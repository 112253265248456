import { useEffect, useState } from 'react';
import { getDataFromAPI, updateDataAPI } from '../../../../utils/query';
import { Button, Col, Form, Row } from 'react-bootstrap';
import ReactQuill from 'react-quill';

import './styles.scss';

const StoreDescriptionTemplatesTab = ({storeID}) => {
    const [worksheetDescriptionTemplate, setWorksheetDescriptionTemplate] = useState();
    const [worksheetFreeDescriptionTemplate, setWorksheetFreeDescriptionTemplate] = useState();
    const [taskcardDescriptionTemplate, setTaskcardDescriptionTemplate] = useState();
    const [taskcardFreeDescriptionTemplate, setTaskcardFreeDescriptionTemplate] = useState();
    const [cbcDescriptionTemplate, setCBCDescriptionTemplate] = useState();
    const [cbcFreeDescriptionTemplate, setCBCFreeDescriptionTemplate] = useState();
    const [boomMysteryDescriptionTemplate, setBoomMysteryDescriptionTemplate] = useState();
    const [boomBoardGameDescriptionTemplate, setBoomBoardGameDescriptionTemplate] = useState();
    const [boomEscapeRoomDescriptionTemplate, setBoomEscapeRoomDescriptionTemplate] = useState();
    const [boomBundleDescriptionTemplate, setBoomBundleDescriptionTemplate] = useState();
    const [tptBoomDescriptionTemplate, setTptBoomDescriptionTemplate] = useState();
    const [tptBoomFreeDescriptionTemplate, setTptBoomFreeDescriptionTemplate] = useState();
    const [printableTaskcardDescriptionTemplate, setPrintableTaskcardDescriptionTemplate] = useState();
    const [boomBundleDigitalPrintableTaskcardDescriptionTemplate, setBoomBundleDigitalPrintableTaskcardDescriptionTemplate] = useState();
    const [boomBigFishSmallFishDescriptionTemplate, setBoomBigFishSmallFishDescriptionTemplate] = useState();

    useEffect(() => {
        fetchStoreDescriptionTemplates();
    }, [storeID]);

    const fetchStoreDescriptionTemplates = async () => {
        const res = await getDataFromAPI('stores/' + storeID + '/');
        setWorksheetDescriptionTemplate(res.data.worksheetDescriptionTemplate);
        setWorksheetFreeDescriptionTemplate(res.data.worksheetFreeDescriptionTemplate);
        setTaskcardDescriptionTemplate(res.data.taskcardDescriptionTemplate);
        setTaskcardFreeDescriptionTemplate(res.data.taskcardFreeDescriptionTemplate);
        setCBCDescriptionTemplate(res.data.cbcDescriptionTemplate);
        setCBCFreeDescriptionTemplate(res.data.cbcFreeDescriptionTemplate);
        setBoomMysteryDescriptionTemplate(res.data.boomMysteryDescriptionTemplate);
        setBoomBundleDescriptionTemplate(res.data.boomBundleDescriptionTemplate);
        setTptBoomDescriptionTemplate(res.data.tptBoomDescriptionTemplate);
        setTptBoomFreeDescriptionTemplate(res.data.tptBoomFreeDescriptionTemplate);
        setBoomBoardGameDescriptionTemplate(res.data.boomBoardGameDescriptionTemplate);
        setBoomEscapeRoomDescriptionTemplate(res.data.boomEscapeRoomDescriptionTemplate);
        setPrintableTaskcardDescriptionTemplate(res.data.printableTaskcardDescriptionTemplate);
        setBoomBundleDigitalPrintableTaskcardDescriptionTemplate(res.data.boomBundleDigitalPrintableTaskcardDescriptionTemplate);
        setBoomBigFishSmallFishDescriptionTemplate(res.data.boomBigFishSmallFishDescriptionTemplate);
    }

    const save = async () => {
        await updateDataAPI('PATCH', 'stores/' + storeID + '/', {
            worksheetDescriptionTemplate,
            worksheetFreeDescriptionTemplate,
            taskcardDescriptionTemplate,
            taskcardFreeDescriptionTemplate,
            cbcDescriptionTemplate,
            cbcFreeDescriptionTemplate,
            boomMysteryDescriptionTemplate,
            boomBoardGameDescriptionTemplate,
            boomBundleDescriptionTemplate,
            boomEscapeRoomDescriptionTemplate,
            tptBoomDescriptionTemplate,
            tptBoomFreeDescriptionTemplate,
            printableTaskcardDescriptionTemplate,
            boomBundleDigitalPrintableTaskcardDescriptionTemplate,
            boomBigFishSmallFishDescriptionTemplate,
        });
        window.alert('Description templates saved successfully');
    }

    return (
        <Form className="store-description-templates-tab">
            <div>
                To replace:
                #subjects, #grades, #title, #nbPages, #activityTags, #skillsOrThemes
            </div>
            <h2>Worksheet Description Template</h2>
            <Row>
                <Col>
                    <Form.Group id="store-worksheet-description-template">
                        <Form.Label>PAID</Form.Label>
                        <ReactQuill theme="snow" value={worksheetDescriptionTemplate} onChange={setWorksheetDescriptionTemplate} />
                    </Form.Group>
                </Col>
                <Col>
                    <Form.Group id="store-worksheet-free-description-template">
                        <Form.Label>FREE</Form.Label>
                        <ReactQuill theme="snow" value={worksheetFreeDescriptionTemplate} onChange={setWorksheetFreeDescriptionTemplate} />
                    </Form.Group>
                </Col>
            </Row>
            <h2>Taskcard</h2>
            <Row>
                <Col>
                    <Form.Group id="store-taskcard-description-template">
                        <Form.Label>PAID</Form.Label>
                        <ReactQuill theme="snow" value={taskcardDescriptionTemplate} onChange={setTaskcardDescriptionTemplate} />
                    </Form.Group>
                </Col>
                <Col>
                    <Form.Group id="store-taskcard-free-description-template">
                        <Form.Label>FREE</Form.Label>
                        <ReactQuill theme="snow" value={taskcardFreeDescriptionTemplate} onChange={setTaskcardFreeDescriptionTemplate} />
                    </Form.Group>
                </Col>
            </Row>
            <Row>
                <Col>
                    <h2>Color By Code</h2>
                    <Form.Group id="store-cbc-description-template">
                        <ReactQuill theme="snow" value={cbcDescriptionTemplate} onChange={setCBCDescriptionTemplate} />
                    </Form.Group>
                </Col>
                <Col>
                    <h2>Boom Mystery</h2>
                    <Form.Group id="store-boom-mystery-description-template">
                        <ReactQuill theme="snow" value={boomMysteryDescriptionTemplate} onChange={setBoomMysteryDescriptionTemplate} />
                    </Form.Group>
                </Col>
            </Row>
            <Row>
                <Col>
                    <h2>Boom Board Game</h2>
                    <Form.Group id="store-boom-board-game-description-template">
                        <ReactQuill theme="snow" value={boomBoardGameDescriptionTemplate} onChange={setBoomBoardGameDescriptionTemplate} />
                    </Form.Group>
                </Col>
                <Col>
                    <h2>Boom Feed The Shark</h2>
                    <Form.Group id="store-boom-big-fish-small-fish-description-template">
                        <ReactQuill theme="snow" value={boomBigFishSmallFishDescriptionTemplate} onChange={setBoomBigFishSmallFishDescriptionTemplate} />
                    </Form.Group>
                </Col>
            </Row>
            <Row>
                <Col>
                    <h2>Boom Bundle</h2>
                    <Form.Group id="store-boom-bundle-description-template">
                        <ReactQuill theme="snow" value={boomBundleDescriptionTemplate} onChange={setBoomBundleDescriptionTemplate} />
                    </Form.Group>
                </Col>
                <Col>
                    <h2>Boom Escape Room</h2>
                    <Form.Group id="store-boom-escape-room-description-template">
                        <ReactQuill theme="snow" value={boomEscapeRoomDescriptionTemplate} onChange={setBoomEscapeRoomDescriptionTemplate} />
                    </Form.Group>
                </Col>
            </Row>
            <h2>TPT Boom</h2>
            <Row>
                <Col>
                    <Form.Group id="store-tpt-boom-description-template">
                        <Form.Label>PAID</Form.Label>
                        <ReactQuill theme="snow" value={tptBoomDescriptionTemplate} onChange={setTptBoomDescriptionTemplate} />
                    </Form.Group>
                </Col>
                <Col>
                    <Form.Group id="store-tpt-boom-free-description-template">
                        <Form.Label>FREE</Form.Label>
                        <ReactQuill theme="snow" value={tptBoomFreeDescriptionTemplate} onChange={setTptBoomFreeDescriptionTemplate} />
                    </Form.Group>
                </Col>
            </Row>
            <Row>
                <Col>
                    <h2>Printable Taskcard</h2>
                    <Form.Group id="store-printable-taskcard-description-template">
                        <ReactQuill theme="snow" value={printableTaskcardDescriptionTemplate} onChange={setPrintableTaskcardDescriptionTemplate} />
                    </Form.Group>
                </Col>
                <Col>
                    <h2>Digital & Printable Bundle</h2>
                    <Form.Group id="store-boom-bundle-digital-printable-taskcards-description-template">
                        <ReactQuill theme="snow" value={boomBundleDigitalPrintableTaskcardDescriptionTemplate} onChange={setBoomBundleDigitalPrintableTaskcardDescriptionTemplate} />
                    </Form.Group>
                </Col>
            </Row>
            <Button className="mt-5" onClick={save}>Save</Button>
        </Form>
    )
}

export default StoreDescriptionTemplatesTab;