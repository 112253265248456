import { useDispatch, useSelector } from 'react-redux';
import { setSelectedActivity } from '../filtersSlice';
import { Form } from 'react-bootstrap';

import './styles.scss';

const FilterByActivity = () => {
    const dispatch = useDispatch();

    const activities = useSelector(state => state.filters.activities);
    const selectedActivity = useSelector((state) => state.filters.selectedActivity);

    return (
        <div className="filter-by-activity">
            <Form.Select
                value={selectedActivity}
                onChange={e => dispatch(setSelectedActivity(e.target.value))}
            >
                <option value="all">All Activities</option>
                {activities.map((activity, index) => (
                    <option key={index} value={activity['slug']}>{activity['label']}</option>
                ))}
            </Form.Select>
        </div>
    );
}

export default FilterByActivity;