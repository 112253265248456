import { Button, Form, ListGroup, Modal } from 'react-bootstrap';

import './styles.scss';
import { updateDataAPI } from '../../../../utils/query';
import { useSelector } from 'react-redux';

const StoreActivitiesModal = ({storeID, store, setStore, hideModal}) => {
    const activities = useSelector(state => state.filters.activities);

    const toggleActivity = async(activity) => {
        if (store.boomActivities === null) {
            setStore({...store, boomActivities: [activity]});
        } else if (store.boomActivities.indexOf(activity) > -1) {
            setStore({...store, boomActivities: store.boomActivities.filter(act => act !== activity)});
        } else {
            setStore({...store, boomActivities: store.boomActivities.concat([activity])});
        }
    }

    const save = async() => {
        await updateDataAPI('PATCH', 'stores/' + storeID + '/', {
            boomActivities: store.boomActivities,
            hasSeasonalProducts: store.hasSeasonalProducts,
        });
        hideModal();
    }

    return (
        <Modal show={true} onHide={hideModal} className="store-activities-modal">
            <Modal.Header closeButton>
                <Modal.Title>
                    Activities
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <ListGroup>
                    <ListGroup.Item as="li" active>Activities</ListGroup.Item>
                    {activities.map(activity => (
                        <ListGroup.Item key={activity['slug']}>
                            <span>{activity['label']}</span>
                            <Form.Check
                                type="checkbox"
                                checked={store.boomActivities?.indexOf(activity['slug']) > -1}
                                onChange={() => toggleActivity(activity['slug'])}
                            />
                        </ListGroup.Item>
                    ))}
                </ListGroup>
                <ListGroup className="mt-3">
                    <ListGroup.Item as="li" active>Seasons</ListGroup.Item>
                    <ListGroup.Item>
                        <span>Has Seasonal Products</span>
                        <Form.Check
                            type="checkbox"
                            checked={store.hasSeasonalProducts}
                            onChange={() => setStore({...store, hasSeasonalProducts: !store.hasSeasonalProducts})}
                        />
                    </ListGroup.Item>
                </ListGroup>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={save}>Save</Button>
            </Modal.Footer>
        </Modal>
    );
}

export default StoreActivitiesModal;