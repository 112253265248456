import { useState, useEffect, useMemo } from 'react';
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';
import {
    faAngleDoubleDown,
    faAngleDoubleUp,
    faCheck,
    faCheckCircle,
    faCloudUpload,
    faEdit,
    faRotateLeft,
    faStar,
    faTrash,
    faUpload,
    faVoicemail,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import DataSetTopicIcon from './data-set-topic-icon';
import { getTopicNamesFromDataSetItems } from '../../../utils/autoContentUtils';
import { updateDataAPI } from '../../../utils/query';
import DataCell from './data-cell';
import { ButtonGroup, Dropdown, Form } from 'react-bootstrap';
import ImageWrapper from '../../common/image-wrapper';
import UploadFile from '../../content-studio/upload-file';
import DataSetImagesEditorModal from './dataset-images-editor-modal';

const DataSetData = ({
    dataSet,
    handleSaveDataSetChanges = async () => { },
    updateIcons,
    updateMultiIconsPerRow,
    setLoading,
}) => {
    const input = dataSet?.topics ?? [];
    const topicNames = useMemo(() => getTopicNamesFromDataSetItems(input), [dataSet?.topics]);

    const [topics, setTopics] = useState([]);
    const [dataSetIcons, setDataSetIcons] = useState([]);
    const [searchingWordID, setSearchingWordID] = useState(null);
    const [maxColumnsData, setMaxColumnsData] = useState(0);
    const [showItemsContent, setShowItemsContent] = useState(false);
    const [editingRowIndex, setEditingRowIndex] = useState(null);
    const [editingJSON, setEditingJSON] = useState(null);

    const [iconUploading, setIconUploading] = useState(false);
    const [uploadedIconUrl, setUploadedIconUrl] = useState(null);
    const [cropRowIndex, setCropRowIndex] = useState(null);
    const [showDataSetImagesEditorModal, setShowDataSetImagesEditorModal] = useState(false);
    const [activeImageIndexInModal, setActiveImageIndexInModal] = useState(null);

    useEffect(() => {
        const id = (new URLSearchParams(window.location.search)).get('id');
        if (id) {
            setShowItemsContent(true);
        }
    }, []);

    useEffect(() => {
        let maxColumnsData = 0;
        for (let i = 0; i < input.length; i++) {
            if (input[i].length > maxColumnsData) {
                maxColumnsData = input[i].length;
            }
        }
        setMaxColumnsData(maxColumnsData);
    }, [input]);

    useEffect(() => {
        const fetchTopicsByName = async () => {
            if (topicNames.length > 0) {
                try {
                    const url = 'resources/fetch-topics-by-name/';

                    const res = await updateDataAPI('POST', url, { topicNames });

                    setTopics(res.data);

                    await fetchDataSetIcons(res.data);
                } catch (err) {
                    console.error(`An error occured when fetching topics by name: ${err.message}`);
                };
            } else {
                setTopics([]);
            };
        }

        fetchTopicsByName();
    }, [topicNames]);

    const fetchDataSetIcons = async (listTopics = []) => {
        if (listTopics.length > 0 && dataSet?.id) {
            try {
                const url = 'resources/fetch-data-set-icons-by-topics-and-data-set/';

                const res = await updateDataAPI('POST', url, {
                    topicIDs: listTopics.map((topic) => topic.id),
                    dataSetID: dataSet.id,
                });

                setDataSetIcons(res.data);
            } catch (err) {
                console.error(`An error occured when fetching topic group icons: ${err.message}`);
            };
        }
    }

    const handleChangeCellValue = async(val, rowIndex, cellIndex, cellItemIndex = null) => {
        const updatedCellValues = [...input];
        if (cellItemIndex === null) {
            updatedCellValues[rowIndex][cellIndex] = val;
        } else {
            updatedCellValues[rowIndex][cellIndex][cellItemIndex] = val;
        }
        // handleUpdateDataSetFields('topics', updatedCellValues);
        const dataSetData = {
            ...dataSet,
            topics: updatedCellValues,
        };
        await handleSaveDataSetChanges(dataSetData);
    }

    const handleDeleteDataSetRow = async (rowIndex) => {
        setLoading(true);
        const updatedCellValues = [...input];
        updatedCellValues.splice(rowIndex, 1);
        const dataSetData = {
            ...dataSet,
            topics: updatedCellValues,
        };
        await updateDataAPI('PATCH', 'resources/data-sets/' + dataSet.id + '/', {
            topics: updatedCellValues,
        });

        await handleSaveDataSetChanges(dataSetData);
        setLoading(false);
    }

    const handleSetRowStar = async(rowIndex) => {
        setLoading(true);
        const updatedCellValues = [...input];
        if (updatedCellValues[rowIndex]['star'] === undefined || updatedCellValues[rowIndex]['star'] === false) {
            updatedCellValues[rowIndex]['star'] = true;
        } else {
            updatedCellValues[rowIndex]['star'] = false;
        }
        const dataSetData = {
            ...dataSet,
            topics: updatedCellValues,
        };

        await updateDataAPI('PATCH', 'resources/data-sets/' + dataSet.id + '/', {
            topics: updatedCellValues,
        });
        await handleSaveDataSetChanges(dataSetData);
        setLoading(false);
    }

    const changeIconCustomTypePhoto = async (rowIndex, cellIndex, cellItemIndex, photoType) => {
        const updatedCellValues = [...input];
        let url;
        if (Array.isArray(updatedCellValues[rowIndex][cellIndex])) {
            url = updatedCellValues[rowIndex][cellIndex][cellItemIndex];
        } else {
            url = updatedCellValues[rowIndex][cellIndex];
        }
        if (photoType === 'real') {
            if (url.indexOf('customTypePhoto') === -1) {
                url += '?customTypePhoto=real';
            } else {
                url = url.replace('customTypePhoto=cartoon', 'customTypePhoto=real');
            }
        } else {
            if (url.indexOf('customTypePhoto') === -1) {
                url += '?customTypePhoto=cartoon';
            } else {
                url = url.replace('customTypePhoto=real', 'customTypePhoto=cartoon');
            }
        }
        if (Array.isArray(updatedCellValues[rowIndex][cellIndex])) {
            updatedCellValues[rowIndex][cellIndex][cellItemIndex] = url;
        } else {
            updatedCellValues[rowIndex][cellIndex] = url;
        }
        const dataSetData = {
            ...dataSet,
            topics: updatedCellValues,
        };

        await handleSaveDataSetChanges(dataSetData);
    }

    const moveRowToTop = async (rowIndex) => {
        setLoading(true);
        const updatedCellValues = [...input];
        let [item] = updatedCellValues.splice(rowIndex, 1);
        updatedCellValues.unshift(item);
        const dataSetData = {
            ...dataSet,
            topics: updatedCellValues,
        };
        await updateDataAPI('PATCH', 'resources/data-sets/' + dataSet.id + '/', {
            topics: updatedCellValues,
        });

        await handleSaveDataSetChanges(dataSetData);
        setLoading(false);
    }

    const moveRowToBottom = async (rowIndex) => {
        setLoading(true);
        const updatedCellValues = [...input];
        let [item] = updatedCellValues.splice(rowIndex, 1);
        updatedCellValues.push(item);
        const dataSetData = {
            ...dataSet,
            topics: updatedCellValues,
        };
        await updateDataAPI('PATCH', 'resources/data-sets/' + dataSet.id + '/', {
            topics: updatedCellValues,
        });

        await handleSaveDataSetChanges(dataSetData);
        setLoading(false);
    }

    const handleClearAllDataSetData = async () => {
        const confirmed = window.confirm('Are you sure want to remove all this rows?');

        if (confirmed) {
            const dataSetData = {
                ...dataSet,
                topics: [],
            };

            await handleSaveDataSetChanges(dataSetData);
        }
    }

    const handleUpdatedTopic = (updatedTopic) => {
        setTopics((prev) => prev.map((t) => {
            if (t.id === updatedTopic.id) return updatedTopic;
            return t;
        }));
    }

    const handleChangeDataSetIcon = (updatedDataSetIcon) => {
        if (updatedDataSetIcon) {
            setDataSetIcons((prev) => prev.map((dataSetIcon) => {
                if (dataSetIcon.id === updatedDataSetIcon.id) {
                    return updatedDataSetIcon;
                }
                return dataSetIcon;
            }));
        }
    }

    const updateTopicIcon = async (topicID, iconID, url) => {
        const res = await updateDataAPI('POST', 'resources/update-topic-icon/', {
            dataSetID: dataSet.id,
            topicID,
            iconID,
        });
        setTopics((prev) => prev.map((topic) => {
            if (topic.id === topicID) {
                return { ...topic, imageUrl: url };
            }
            return topic;
        }));
        if (dataSetIcons.find((dataSetIcon) => dataSetIcon.topic === topicID)) {
            setDataSetIcons((prev) => prev.map((dataSetIcon) => {
                if (dataSetIcon.topic === topicID) {
                    return res.data;
                }
                return dataSetIcon;
            }));
        } else {
            setDataSetIcons(dataSetIcons.concat(res.data));
        }
        setSearchingWordID(null);
    }

    const updateJSON = async() => {
        const jsonObj = JSON.parse(editingJSON);
        const newData = dataSet.topics.map((row, rowIndex) => {
            if (rowIndex === editingRowIndex) {
                return jsonObj;
            }
            return row;
        })
        await updateDataAPI('PATCH', 'resources/data-sets/' + dataSet.id + '/', {
            topics: newData,
        });
        await handleSaveDataSetChanges({ ...dataSet, topics: newData });
        setEditingRowIndex(null);
        setEditingJSON(null);
    }

    const playAudio = async(audioUrl) => {
        console.log(audioUrl);
        const audio = new Audio(audioUrl);
        audio.play();
    }

    const genAudio = async(rowIndex) => {
        setLoading(true);
        try {
            const res = await updateDataAPI('POST', 'resources/gen-audio-for-dataset/', {
                id: dataSet.id,
                clear: true,
                rowIndex,
            });
            handleSaveDataSetChanges({
                ...dataSet,
                topics: res.data['topics'],
            });
        } catch (err) {
            window.alert('check dataset activity, only support MCQ');
        }
        setLoading(false);
    }

    const recoverOriginalIcon = async(rowIndex) => {
        setLoading(true);
        const newData = dataSet.topics.map((row, rIndex) => {
            if (rowIndex === rIndex) {
                return {...row, icon: row['original_icon']};
            }
            return row;
        })
        await updateDataAPI('PATCH', 'resources/data-sets/' + dataSet.id + '/', {
            topics: newData,
        });
        await handleSaveDataSetChanges({ ...dataSet, topics: newData });
        setLoading(false);
    }

    return (
        <div>
            {showItemsContent ? (
                <div>
                    <div onClick={() => setShowItemsContent(false)} className="toggle-items-table">Hide Items</div>
                    <Table striped bordered hover responsive>
                        <tbody>
                            {(input ?? []).map((row, rowIndex) => (
                                <tr key={rowIndex}>
                                    <td>
                                        <span>{rowIndex + 1}</span>
                                        {rowIndex < dataSet.nbQuestionsValidated && (
                                            <div className="check-icon"><FontAwesomeIcon icon={faCheckCircle} /></div>
                                        )}
                                    </td>
                                    {dataSet.dataType === 'JSON' ? (
                                        <td>
                                            {editingRowIndex === rowIndex ? (
                                                <div>
                                                    <Form.Control as="textarea" rows={10} value={editingJSON} onChange={e => setEditingJSON(e.target.value)} />
                                                </div>
                                            ) : (
                                                <pre>{JSON.stringify(row, null, 4)}</pre>
                                            )}
                                        </td>
                                    ) : (
                                        row.map((cell, cellIndex) => (
                                            <td key={cellIndex} className="data-cell">
                                                {Array.isArray(cell) ? (
                                                    cell.map((cellItem, cellItemIndex) => (
                                                        <DataCell
                                                            cell={cellItem}
                                                            rowIndex={rowIndex}
                                                            cellIndex={cellIndex}
                                                            cellItemIndex={cellItemIndex}
                                                            changeIconCustomTypePhoto={type => changeIconCustomTypePhoto(rowIndex, cellIndex, cellItemIndex, type)}
                                                            handleDeleteDataSetRow={handleDeleteDataSetRow}
                                                            handleChangeCellValue={handleChangeCellValue} />
                                                    ))
                                                ) : (
                                                    <DataCell
                                                        cell={cell}
                                                        rowIndex={rowIndex}
                                                        cellIndex={cellIndex}
                                                        changeIconCustomTypePhoto={type => changeIconCustomTypePhoto(rowIndex, cellIndex, null, type)}
                                                        handleDeleteDataSetRow={handleDeleteDataSetRow}
                                                        handleChangeCellValue={handleChangeCellValue} />
                                                )}
                                            </td>
                                        ))
                                    )}
                                    {Array.from({ length: maxColumnsData - row.length }).map((_, index) => (
                                        <td key={index} className="data-cell"></td>
                                    ))}
                                    <td className="btns-cell">
                                        {'icon' in row && (
                                            <img
                                                className="row-icon"
                                                src={row['icon']}
                                                onClick={() => {
                                                    setShowDataSetImagesEditorModal(true);
                                                    setActiveImageIndexInModal(rowIndex);
                                                }}
                                            />
                                        )}
                                        {'choices' in row && (
                                            <div className="choice-images">
                                                {row['choices'].filter(choice => choice.startsWith('http')).map((choice, choiceIndex) => (
                                                    <ImageWrapper key={choiceIndex} src={choice} />
                                                ))}
                                            </div>
                                        )}
                                        {'correct_choice' in row && row['correct_choice'].startsWith('http') && (
                                            <div className="correct-choice-image">
                                                <ImageWrapper src={row['correct_choice']} />
                                            </div>
                                        )}
                                        <div>
                                            <Button variant={row['star'] ? 'warning' : 'secondary'}
                                                size="sm" onClick={() => handleSetRowStar(rowIndex)}>
                                                <FontAwesomeIcon icon={faStar} />
                                            </Button>
                                            <Button variant="secondary" size="sm" onClick={() => moveRowToTop(rowIndex)}>
                                                <FontAwesomeIcon icon={faAngleDoubleUp} />
                                            </Button>
                                            <Button variant="secondary" size="sm" onClick={() => moveRowToBottom(rowIndex)}>
                                                <FontAwesomeIcon icon={faAngleDoubleDown} />
                                            </Button>
                                            <Button variant="danger" size="sm" onClick={() => handleDeleteDataSetRow(rowIndex)}>
                                                <FontAwesomeIcon icon={faTrash} />
                                            </Button>
                                            {editingRowIndex !== rowIndex ? (
                                                <Button variant="secondary" size="sm" onClick={() => {
                                                    setEditingJSON(JSON.stringify(row, null, 4));
                                                    setEditingRowIndex(rowIndex);
                                                }}>
                                                    <FontAwesomeIcon icon={faEdit} />
                                                </Button>
                                            ) : (
                                                <Button variant="secondary" size="sm" onClick={updateJSON}>
                                                    <FontAwesomeIcon icon={faCheck} />
                                                </Button>
                                            )}
                                        </div>
                                        <div>
                                            <Button size="sm" variant="secondary" onClick={() => document.querySelector('.ds-' + dataSet.id + '-' + rowIndex + '-upload input[type=file]').click()} className="upload-icon-btn">
                                                <FontAwesomeIcon icon={faUpload} />
                                                <div>
                                                    <UploadFile
                                                        id={dataSet.id}
                                                        className={'ds-' + dataSet.id + '-' + rowIndex + '-upload'}
                                                        setLoading={setIconUploading}
                                                        uploadUrl={'resources/upload-data-set-icons/'}
                                                        allowedFileExtentions={['png', 'jpg', 'jpeg']}
                                                        fieldName={'temp'}
                                                        uploadedFileName={null}
                                                        setUploadedFileName={data => {
                                                            navigator.clipboard.writeText(Object.values(data)[0]);
                                                            updateIcons({[rowIndex + 1]: Object.values(data)[0]});
                                                        }}
                                                        multiple={true}
                                                    />
                                                    {iconUploading ? (
                                                        <div>Uploading...</div>
                                                    ) : (
                                                        uploadedIconUrl && (
                                                            <div className="uploaded-icon-url">
                                                                <img src={uploadedIconUrl} />
                                                                <Form.Control value={uploadedIconUrl} disabled />
                                                            </div>
                                                        )
                                                    )}
                                                </div>
                                            </Button>
                                            <Button size="sm" variant="secondary" onClick={() => document.querySelector('.ds-' + dataSet.id + '-' + rowIndex + '-upload-multi input[type=file]').click()} className="multi-upload-icon-btn">
                                                <FontAwesomeIcon icon={faCloudUpload} />
                                                <div>
                                                    <div>many</div>
                                                    <UploadFile
                                                        id={dataSet.id}
                                                        className={'ds-' + dataSet.id + '-' + rowIndex + '-upload-multi'}
                                                        setLoading={setIconUploading}
                                                        uploadUrl={'resources/upload-data-set-icons/'}
                                                        allowedFileExtentions={['png', 'jpg', 'jpeg']}
                                                        fieldName={'temp'}
                                                        uploadedFileName={null}
                                                        setUploadedFileName={data => {
                                                            console.log(data);
                                                            updateMultiIconsPerRow(rowIndex, Object.values(data));
                                                        }}
                                                        multiple={true}
                                                    />
                                                    {iconUploading ? (
                                                        <div>Uploading...</div>
                                                    ) : (
                                                        uploadedIconUrl && (
                                                            <div className="uploaded-icon-url">
                                                                <img src={uploadedIconUrl} />
                                                                <Form.Control value={uploadedIconUrl} disabled />
                                                            </div>
                                                        )
                                                    )}
                                                </div>
                                            </Button>
                                            {row['icon'] && row['original_icon'] && (
                                                <Button size="sm" variant="secondary" onClick={() => recoverOriginalIcon(rowIndex)}>
                                                    <FontAwesomeIcon icon={faRotateLeft} />
                                                </Button>
                                            )}
                                        </div>
                                        <div>
                                            <Dropdown as={ButtonGroup} size="sm">
                                                <Button variant={row['audio'] ? 'info' : 'secondary'} size="sm" onClick={() => {
                                                    if (row['audio']) {
                                                        playAudio(row['audio']);
                                                    }
                                                }}>
                                                    <FontAwesomeIcon icon={faVoicemail} />
                                                </Button>

                                                <Dropdown.Toggle split id="audio-mail-dropdown" variant="secondary" />

                                                <Dropdown.Menu>
                                                    <Dropdown.Item onClick={() => genAudio(rowIndex)}>Gen Audio</Dropdown.Item>
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </div>
                                    </td>
                                    <DataSetTopicIcon
                                        topic={topics[rowIndex]}
                                        dataSetIcons={dataSetIcons}
                                        searchingWordID={searchingWordID}
                                        setSearchingWordID={setSearchingWordID}
                                        updateTopicIcon={updateTopicIcon}
                                        handleUpdatedTopic={handleUpdatedTopic}
                                        handleChangeDataSetIcon={handleChangeDataSetIcon}
                                    />
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                    {input.length > 0 && (
                        <Button variant="danger" size="sm" onClick={handleClearAllDataSetData} className="remove-all-btn">
                            Remove all
                        </Button>
                    )}
                </div>
            ) : (
                <span onClick={() => setShowItemsContent(true)} className="toggle-items-table">Show Items</span>
            )}

            {showDataSetImagesEditorModal && (
                <DataSetImagesEditorModal
                    dataSet={dataSet}
                    defaultActiveImageIndex={activeImageIndexInModal}
                    hideModal={() => setShowDataSetImagesEditorModal(false)}
                    handleSaveDataSetChanges={handleSaveDataSetChanges}
                />
            )}
        </div>
    )
};

export default DataSetData;