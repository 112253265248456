import 'bootstrap/dist/css/bootstrap.min.css';
import './App.scss';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import { Route, BrowserRouter as Router, Switch } from "react-router-dom";
import LoginPage from './pages/login';
import HomePage from './pages/home';
import AuthRoute from './AuthRoute';
import IdeaListPage from './pages/idea-list';
import StaffsPage from './pages/staffs';
import UserFeedbacksPage from './pages/user-feedbacks';
import FormatAIChatTaskcardPage from './pages/format-ai-chat-taskcard';
import PublicProductDescriptionPage from './pages/public-product-description';
import UploadTicketsPage from './pages/upload-tickets';
import ColorByCodeMakerPage from './pages/colorbycode-maker';
import { setRoutePermissions, setActivityPermissions } from './redux/permissionsSlice';
import { useEffect, useState } from 'react';
import { getDataFromAPI } from './utils/query';
import { useDispatch } from 'react-redux';
import StaffPerformancePage from './pages/staff-performance';
import ContentStudioPage from './pages/content-studio';
import TemplatesManagementPage from './pages/auto-content/templates-management';
import TrainingTextsPage from './pages/training-texts';
import DesignTicketsPage from './pages/design-ticket';
import TemplateInteractivePrompt from './components/auto-content/template-interactive-prompt';
import TaskManagementPage from './pages/task-management';
import QuickSearchIdeaModal from './components/quick-search-idea-modal';
import StaffGenDataTasksDashboardPage from './pages/staff-gen-data-tasks-dashboard';
import BotStatusDashboardPage from './pages/bot-status-dashboard';
import FetchExamplesProducts from './pages/fetch-examples-products';
import DataSetPage from './pages/data-set';
import EventsPage from './pages/events';
import TopicsPage from './pages/topics';
import TestFunctionPage from './pages/test-function';
import MultiLanguagesIdeasPage from './pages/multi-languages-ideas';
import OutlinePage from './pages/outline';
import OutlinesPage from './pages/outlines';
import Constant from './Constant';
import { setEvents } from './components/auto-content/autoContentSlice';
import PerformanceTicketPage from './pages/performance-ticket';
import PerformanceReportsPage from './pages/performance-reports';
import ProductPerformancePage from './pages/product-performance';
import CopyTemplateToDevPage from './pages/copy-template-to-dev';
import TPTCrawlerPage from './pages/tpt-crawler';
import TPTCrawlRequestsPage from './pages/tpt-crawl-requests';
import ProductMapPage from './pages/product-map';
import ApplyImageToDataSetPage from './pages/apply-image-to-dataset';
import CollectedResourcesPage from './pages/collected-resources';
import CollectedResourcesSearchQueriesPage from './pages/collected-resources/search-queries';
import StoreMapPage from './pages/store-map';
import StoreTemplatesConfigPage from './pages/store-templates-config';

function App() {
    const dispatch = useDispatch();
    const [showQuickSearchIdeaModal, setShowQuickSearchIdeaModal] = useState(false);
    const [token, setToken] = useState(null);

    useEffect(() => {
        setToken(localStorage.getItem(Constant.LOCAL_STORAGE_TOKEN));
    }, []);

    useEffect(() => {
        const handleKeyDown = (e) => {
            if ((e.ctrlKey || e.metaKey) && e.key === 'i') {
                e.preventDefault();
                setShowQuickSearchIdeaModal(true);
            }
        }

        document.addEventListener('keydown', handleKeyDown);

        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, []);

    useEffect(() => {
        const getPermissons = async () => {
            if (!token) return;
            const res = await getDataFromAPI('product-dev/staff-role-permissions/');

            let activityPermissions = {};
            let routePermissions = {};

            res.data.forEach(permission => {
                if (permission.type === 'route') {
                    routePermissions[permission.action] = permission.allowRoles;
                } else if (permission.type === 'activity') {
                    activityPermissions[permission.action] = permission.allowRoles;
                }
            });

            dispatch(setRoutePermissions(routePermissions));
            dispatch(setActivityPermissions(activityPermissions));
        };

        getPermissons();
    }, [token]);

    useEffect(() => {
        const getEvents = async () => {
            if (!token) return;
            const res = await getDataFromAPI('resources/events/');

            dispatch(setEvents(res.data));
        };

        getEvents();
    }, [token]);

    return (
        <div className="App">
            <Router>
                <Switch>
                    <Route
                        exact
                        path="/login"
                        component={LoginPage}
                    />
                    <AuthRoute
                        exact
                        path="/"
                        component={HomePage}
                    />
                    <AuthRoute
                        exact
                        path="/task-management"
                        component={TaskManagementPage}
                    />
                    <AuthRoute
                        exact
                        path="/staff-gen-data-tasks-dashboard"
                        component={StaffGenDataTasksDashboardPage}
                    />
                    <AuthRoute
                        exact
                        path="/bot-status-dashboard"
                        component={BotStatusDashboardPage}
                    />
                    <AuthRoute
                        exact
                        path="/test-function"
                        component={TestFunctionPage}
                    />
                    <AuthRoute
                        exact
                        path="/format-ai-chat-taskcard"
                        component={FormatAIChatTaskcardPage}
                    />
                    <AuthRoute
                        exact
                        path="/ideas"
                        component={IdeaListPage}
                    />
                    <AuthRoute
                        exact
                        path="/staffs"
                        component={StaffsPage}
                    />
                    <AuthRoute
                        exact
                        path="/user-feedbacks"
                        component={UserFeedbacksPage}
                    />
                    <AuthRoute
                        exact
                        path="/upload-tickets"
                        component={UploadTicketsPage}
                    />
                    <Route
                        exact
                        path="/public-product-description/:customID"
                        component={PublicProductDescriptionPage}
                    />
                    <AuthRoute
                        exact
                        path="/colorbycode-maker"
                        component={ColorByCodeMakerPage}
                    />
                    <AuthRoute
                        exact
                        path="/staff-performance"
                        component={StaffPerformancePage}
                    />
                    <AuthRoute
                        exact
                        path="/content-studio"
                        component={ContentStudioPage}
                    />
                    <AuthRoute
                        exact
                        path="/templates-management"
                        component={TemplatesManagementPage}
                    />
                    <AuthRoute
                        exact
                        path="/training-texts"
                        component={TrainingTextsPage}
                    />
                    <AuthRoute
                        exact
                        path="/design-tickets"
                        component={DesignTicketsPage}
                    />
                    <AuthRoute
                        exact
                        path="/template-interactive-prompt"
                        component={TemplateInteractivePrompt}
                    />
                    <AuthRoute
                        exact
                        path="/fetch-examples-products"
                        component={FetchExamplesProducts}
                    />
                    <AuthRoute
                        exact
                        path="/data-set"
                        component={DataSetPage}
                    />
                    <AuthRoute
                        exact
                        path="/events"
                        component={EventsPage}
                    />
                    <AuthRoute
                        exact
                        path="/topics"
                        component={TopicsPage}
                    />
                    <AuthRoute
                        exact
                        path="/multi-languages-ideas"
                        component={MultiLanguagesIdeasPage}
                    />
                    <AuthRoute
                        exact
                        path="/outlines"
                        component={OutlinesPage}
                    />
                    <AuthRoute
                        exact
                        path="/outline"
                        component={OutlinePage}
                    />
                    <AuthRoute
                        exact
                        path="/performance-ticket"
                        component={PerformanceTicketPage}
                    />
                    <AuthRoute
                        exact
                        path="/performance-reports"
                        component={PerformanceReportsPage}
                    />
                    <AuthRoute
                        exact
                        path="/product-performance"
                        component={ProductPerformancePage}
                    />
                    <AuthRoute
                        exact
                        path="/copy-to-dev"
                        component={CopyTemplateToDevPage}
                    />
                    <AuthRoute
                        exact
                        path="/tpt-crawl-requests"
                        component={TPTCrawlRequestsPage}
                    />
                    <AuthRoute
                        exact
                        path="/tpt-crawler"
                        component={TPTCrawlerPage}
                    />
                    <AuthRoute
                        exact
                        path="/product-map"
                        component={ProductMapPage}
                    />
                    <AuthRoute
                        exact
                        path="/store-map"
                        component={StoreMapPage}
                    />
                    <AuthRoute
                        exact
                        path="/apply-image-to-dataset"
                        component={ApplyImageToDataSetPage}
                    />
                    <AuthRoute
                        exact
                        path="/collected-resources"
                        component={CollectedResourcesPage}
                    />
                    <AuthRoute
                        exact
                        path="/collected-resources-search-queries"
                        component={CollectedResourcesSearchQueriesPage}
                    />
                    <AuthRoute
                        exact
                        path="/store-templates-config"
                        component={StoreTemplatesConfigPage}
                    />
                </Switch>
            </Router>

            {showQuickSearchIdeaModal && (
                <QuickSearchIdeaModal hideModal={() => setShowQuickSearchIdeaModal(false)} />
            )}
        </div>
    );
}

export default App;
