import { useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';
import './styles.scss';
import { getDataFromAPI, updateDataAPI } from '../../../../utils/query';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClose, faEdit, faRobot } from '@fortawesome/free-solid-svg-icons';
import { Form } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { updateIdeaTemplateContent } from '../../autoContentSlice';
import { warningUserWhenPageIsValidate } from '../../../../utils/autoContentUtils';

const ZonesAndAIResponseModal = ({ ideaTemplate, hideModal, refreshPage, setLoading, answerKey, page, pageResultDisplayMode }) => {
    const dispatch = useDispatch();

    const [zones, setZones] = useState([]);
    const [editingZones, setEditingZones] = useState([]);

    useEffect(() => {
        fetchZonesAndAIResponse();
    }, []);

    const fetchZonesAndAIResponse = async () => {
        const res = await getDataFromAPI('auto-content/get-zones-and-ai-response/?ideaTemplateID=' + ideaTemplate.id);
        setZones(res.data);
    }

    const updateZoneValues = async () => {
        setLoading(true);
        const changes = [];
        for (let i = 0; i < editingZones.length; i++) {
            const edittingZone = zones.find((zone) => zone.id === editingZones[i]);
            changes.push({
                accessPath: edittingZone.accessPath,
                value: edittingZone.value,
            })
        }
        await updateDataAPI('POST', 'auto-content/update-idea-template-content/', {
            ideaTemplateID: ideaTemplate.id,
            changes,
        });

        rerenderImage();
        hideModal();
        setLoading(false);
    }

    const generateContent = async (zone, actionType) => {
        if (warningUserWhenPageIsValidate([page])) return;

        if (window.confirm('Are you sure you want to use AI to generate content?')) {
            setLoading(true);
            let res;
            if (actionType === 'regenerate') {
                res = await updateDataAPI('POST', 'auto-content/fix-ai-response-field/?ideaTemplateID=' + ideaTemplate.id + '&zoneID=' + zone.id);
            } else {
                try {
                    res = await updateDataAPI('POST', 'auto-content/paraphrase-ai-response-field/?ideaTemplateID=' + ideaTemplate.id + '&zoneID=' + zone.id);
                } catch (err) {
                    window.alert('Page ' + ideaTemplate.id + ' paraphrase failed. Please try again.');
                    setLoading(false);
                    return;
                }
            }
            rerenderImage();
            setZones(zones.map(z => {
                if (z.id === zone.id) {
                    return { ...z, value: res.data['value'] };
                }
                return z;
            }));
            setEditingZones([]);
            hideModal();
            setLoading(false);
        }
    }

    const rerenderImage = async () => {
        await updateDataAPI('PATCH', 'auto-content/idea-templates/' + ideaTemplate.id + '/', {
            imageUrl: null,
            lastExportTime: null,
        });
        dispatch(updateIdeaTemplateContent({
            ideaTemplateID: ideaTemplate.id,
            field: 'updated',
            value: true,
        }));
        refreshPage();
    }

    const handleEditZone = (zone) => {
        if (warningUserWhenPageIsValidate([page])) return;

        const updatedEditingZones = editingZones.includes(zone.id)
            ? editingZones.filter((id) => id !== zone.id)
            : [...editingZones, zone.id];
        setEditingZones(updatedEditingZones);
    }

    const handleCloseEditZone = (zoneID) => {
        setEditingZones((prev) => prev.filter((id) => id !== zoneID));
    }

    return (
        <Modal show={true} fullscreen={true} onHide={hideModal} className="zones-and-ai-response-modal">
            <Modal.Header closeButton>
                <Modal.Title>
                    Zones and AI Response
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <img src={ideaTemplate.templateData.imageUrl} />
                <div>
                    <Table striped bordered hover>
                        <thead>
                            <tr>
                                <th>Zone</th>
                                <th>Text</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {zones.map(zone => (
                                <tr key={zone.id}>
                                    <td className="zone-name-cell">
                                        <div>
                                            {zone.name.indexOf('>') === -1 ? (
                                                <strong>{zone.name}</strong>
                                            ) : (
                                                <span>{zone.name}</span>
                                            )}
                                        </div>
                                        {zone.validators && (
                                            <div className="zone-validators">
                                                <h3>Validators</h3>
                                                <div>{zone.validators}</div>
                                            </div>
                                        )}
                                    </td>
                                    <td className="zone-value-cell">
                                        {(editingZones.includes(zone.id)) ? (
                                            <Form.Control
                                                value={zone.value}
                                                as="textarea"
                                                rows={5}
                                                onChange={(e) => {
                                                    const updatedZones = zones.map((z) => {
                                                        if (z.id === zone.id) {
                                                            return { ...z, value: e.target.value };
                                                        }
                                                        return z;
                                                    });
                                                    setZones(updatedZones);
                                                }}
                                            />
                                        ) : (
                                            (zone.value && zone.value.startsWith('https')) ? (
                                                <img src={zone.value} />
                                            ) : (
                                                <span>{zone.value}</span>
                                            )
                                        )}
                                    </td>

                                    {(pageResultDisplayMode === 'simple' || (pageResultDisplayMode === 'design' && ideaTemplate.imageUrl)) && (
                                        <td className="actions-cell">
                                            {zone.value && (
                                                <div className="nb-words">
                                                    {zone.value.split(' ').length} words
                                                </div>
                                            )}
                                            {!editingZones.includes(zone.id) ? (
                                                <FontAwesomeIcon icon={faEdit} onClick={() => handleEditZone(zone)} />
                                            ) : (
                                                <FontAwesomeIcon icon={faClose} onClick={() => handleCloseEditZone(zone.id)} />
                                            )}
                                            <FontAwesomeIcon icon={faRobot} onClick={() => generateContent(zone, 'regenerate')} />
                                            <Button size="sm" className="mt-3" variant="info" onClick={() => generateContent(zone, 'paraphrase')}>Paraphrase</Button>
                                        </td>
                                    )}
                                </tr>
                            ))}
                        </tbody>
                    </Table>

                    {editingZones.length > 0 && (
                        <Button onClick={updateZoneValues} className="mb-2">
                            Save changes
                        </Button>
                    )}

                    <Form.Control
                        as="textarea" rows={7}
                        value={answerKey} onChange={e => {
                            if (warningUserWhenPageIsValidate([page])) return;
                            dispatch(updateIdeaTemplateContent({
                                ideaTemplateID: ideaTemplate.id,
                                field: 'answerKey',
                                value: e.target.value
                            }))
                        }} />
                </div>
            </Modal.Body>
        </Modal>
    )
}

export default ZonesAndAIResponseModal;