import { useEffect, useState } from 'react';
import { Button, Col, Form, Modal, Row, Spinner, Tab, Tabs } from 'react-bootstrap';
import './styles.scss';
import { getDataFromAPI, updateDataAPI } from '../../../utils/query';
import { useSelector } from 'react-redux';
import GenDataTicketsTable from './gen-data-tickets-table';
import MultiValues from '../../../forms/multi-values';
import FilterKeywordsByCategory from '../../../filters/keywords-by-category';
import DataSetsTable from './data-sets-table';

const KeywordInfoModal = ({keywordID = null, updateKeyword, removeKeyword, hideModal, navigation = false, navigateToKeyword}) => {
    const stores = useSelector(state => state.filters.stores);

    const [name, setName] = useState(null);
    const [grades, setGrades] = useState(null);
    const [parent, setParent] = useState(null);
    const [subKeywords, setSubKeywords] = useState(null);
    const [thumbnailTitle, setThumbnailTitle] = useState(null);
    const [subjectTPTs, setSubjectTPTs] = useState(null);
    const [subjectBooms, setSubjectBooms] = useState(null);
    const [boomBundleID, setBoomBundleID] = useState(null);
    const [tptBundleID, setTptBundleID] = useState(null);
    const [boomStores, setBoomStores] = useState(null);
    const [tptStore, setTPTStore] = useState(null);
    const [ggSheetTab, setGgSheetTab] = useState(null);
    const [loading, setLoading] = useState(null);
    const [keyword, setKeyword] = useState(null);

    useEffect(() => {
        if (keywordID) {
            fetchKeyword();
        }
    }, []);

    const fetchKeyword = async() => {
        setLoading(true);
        const res = await getDataFromAPI('resources/keywords/' + keywordID + '/');
        const keyword = res.data;
        setKeyword(keyword);
        setName(keyword.name);
        setGrades(keyword?.grades);
        setParent(keyword?.parent);
        setSubKeywords(keyword?.subKeywords);
        setThumbnailTitle(keyword?.thumbnailTitle);
        setSubjectTPTs(keyword?.subjectTPTs);
        setSubjectBooms(keyword?.subjectBooms);
        setBoomBundleID(keyword?.boomBundleID);
        setTptBundleID(keyword?.tptBundleID);
        setBoomStores(keyword?.boomStores ? keyword.boomStores.join(',') : '');
        setTPTStore(keyword?.tptStore);
        setGgSheetTab(keyword?.ggSheetTab);
        setLoading(false);
    }

    const save = async(action = null) => {
        if (keywordID !== null) {
            setLoading(true);
            await updateDataAPI('PATCH', 'resources/keywords/' + keywordID + '/', {
                name, grades, parent, subKeywords, thumbnailTitle, subjectTPTs, subjectBooms, boomBundleID, tptBundleID, boomStores: boomStores.length > 0 ? boomStores.split(',') : [], tptStore, ggSheetTab,
            });
            const res = await getDataFromAPI('resources/keywords/' + keywordID + '/?additionalFields=ideas,dataSets');
            if (updateKeyword) {
                await updateKeyword(res.data);
            }
            setKeyword(res.data);
            setLoading(false);
        } else if (name !== null) {
            setLoading(true);
            const resKeyword = await updateDataAPI('POST', 'resources/keywords/', {
                name, grades, parent, subKeywords, thumbnailTitle, subjectTPTs, subjectBooms, boomBundleID, tptBundleID, boomStores: boomStores && boomStores.length > 0 ? boomStores.split(',') : [], tptStore, ggSheetTab,
            });
            const res = await getDataFromAPI('resources/keywords/' + resKeyword.data['id'] + '/?additionalFields=ideas,dataSets');
            if (updateKeyword) {
                await updateKeyword(res.data);
            }
            setKeyword(res.data);
            setLoading(false);
        }
        if (action === 'hide') {
            hideModal();
        }
    }

    const deleteKeyword = async() => {
        if (window.confirm('This will delete all data sets and ideas linked to this keyword. Are you sure?')) {
            setLoading(true);
            await updateDataAPI('POST', 'resources/delete-keyword/', {keywordID: keywordID});
            if (removeKeyword) {
                removeKeyword(keywordID);
            }
            setLoading(false);
            hideModal();
        }
    }

    return (
        <Modal
            show={true}
            onHide={hideModal}
            contentLabel="Keyword Info Modal"
            className="keyword-info-modal"
            fullscreen={true}
        >
            <Modal.Header closeButton>
                <Modal.Title>
                    {keywordID !== null ? keywordID + '. ' + name : 'New Keyword'}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Tabs id="keyword-tabs">
                    <Tab eventKey="info" title="Info">
                        <Form className="mt-3">
                            <Row>
                                <Col>
                                    <Form.Group>
                                        <Form.Label>Name</Form.Label>
                                        <Form.Control value={name} onChange={e => setName(e.target.value)} />
                                    </Form.Group>
                                </Col>
                                <Col>
                                    <Form.Group>
                                        <Form.Label>Grades</Form.Label>
                                        <Form.Control value={grades} onChange={e => setGrades(e.target.value)} />
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Form.Group>
                                        <Form.Label>Category</Form.Label>
                                        <FilterKeywordsByCategory
                                            selectedCategory={parent}
                                            setSelectedCategory={setParent}
                                        />
                                    </Form.Group>
                                </Col>
                                <Col>
                                    <Form.Group>
                                        <Form.Label>Google Sheet Tab</Form.Label>
                                        <Form.Control value={ggSheetTab} onChange={e => setGgSheetTab(e.target.value)} />
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Form.Group>
                                        <Form.Label>Sub Keywords</Form.Label>
                                        <Form.Control value={subKeywords} onChange={e => setSubKeywords(e.target.value)} />
                                    </Form.Group>
                                </Col>
                                <Col>
                                    <Form.Group>
                                        <Form.Label>Thumbnail Title</Form.Label>
                                        <Form.Control value={thumbnailTitle} onChange={e => setThumbnailTitle(e.target.value)} />
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Form.Group>
                                        <Form.Label>Subject TPTs</Form.Label>
                                        <Form.Control value={subjectTPTs} onChange={e => setSubjectTPTs(e.target.value)} />
                                    </Form.Group>
                                </Col>
                                <Col>
                                    <Form.Group>
                                        <Form.Label>Subject Booms</Form.Label>
                                        <Form.Control value={subjectBooms} onChange={e => setSubjectBooms(e.target.value)} />
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Form.Group>
                                        <Form.Label>Boom Bundle ID</Form.Label>
                                        <Form.Control value={boomBundleID} onChange={e => setBoomBundleID(e.target.value)} type="number" />
                                    </Form.Group>
                                </Col>
                                <Col>
                                    <Form.Group>
                                        <Form.Label>TPT Bundle ID</Form.Label>
                                        <Form.Control value={tptBundleID} onChange={e => setTptBundleID(e.target.value)} type="number" />
                                    </Form.Group>
                                </Col>
                                <Col>
                                    <Form.Group>
                                        <Form.Label>Boom Store</Form.Label>
                                        <MultiValues
                                            value={boomStores}
                                            setValue={val => setBoomStores(val)}
                                            choicesDataAPI="stores/?active=true&marketplace=boom"
                                            labelField="name"
                                            valueField="id" />

                                    </Form.Group>
                                </Col>
                                <Col>
                                    <Form.Group>
                                        <Form.Label>TPT Store</Form.Label>
                                        <Form.Select value={tptStore} onChange={e => setTPTStore(e.target.value)}>
                                            <option></option>
                                            {stores.filter(store => store.marketplace === 'tpt').map(store => (
                                                <option key={store.id} value={store.id}>{store.name}</option>
                                            ))}
                                        </Form.Select>
                                    </Form.Group>
                                </Col>
                            </Row>
                        </Form>
                    </Tab>
                    <Tab eventKey="genDataTickets" title="Gen Data Tickets">
                        {keyword && (
                            <GenDataTicketsTable keyword={keyword} />
                        )}
                    </Tab>
                    <Tab eventKey="dataSets" title="Data Sets">
                        {keyword && (
                            <DataSetsTable keyword={keyword} />
                        )}
                    </Tab>
                </Tabs>
            </Modal.Body>
            <Modal.Footer>
                <div>
                    {keywordID !== null && (
                        <Button variant="danger" onClick={deleteKeyword}>Delete</Button>
                    )}
                </div>
                {navigation && (
                    <div>
                        <Button variant="secondary" onClick={() => navigateToKeyword('prev')}>Prev</Button>
                        <Button variant="secondary" onClick={() => navigateToKeyword('next')}>Next</Button>
                    </div>
                )}
                <div>
                    <Button onClick={() => save()}>Save</Button>
                    <Button onClick={() => save('hide')}>Save & Close</Button>
                </div>
            </Modal.Footer>

            {loading && (
                <div className="loading-container">
                    <Spinner animation="border" variant="light" />
                </div>
            )}
        </Modal>
    );
}

export default KeywordInfoModal;