import { useEffect, useState } from 'react';
import { Button, Table } from 'react-bootstrap';
import { getDataFromAPI } from '../../../../utils/query';

const DataSetsTable = ({keyword}) => {
    const [dataSets, setDataSets] = useState([]);

    useEffect(() => {
        fetchDataSets();
    }, []);

    const fetchDataSets = async() => {
        const res = await getDataFromAPI('resources/data-sets/?keywordID=' + keyword.id);
        setDataSets(res.data['results']);
    }

    return (
        <Table striped bordered hover className="keyword-dataSets">
            <thead>
                <tr>
                    <th>ID</th>
                    <th>Data Type</th>
                    <th>Activity</th>
                    <th>Source</th>
                    <th>Season</th>
                    <th>Quality</th>
                    <th>Audio</th>
                    <th>Store</th>
                    <th>Validated</th>
                </tr>
            </thead>
            <tbody>
                {dataSets.map(dataSet => (
                    <tr key={dataSet.id} className="dataSet">
                        <td>
                            <a href={'/data-set/?id=' + dataSet.id} target="_blank">
                                {dataSet.id}
                            </a>
                        </td>
                        <td>{dataSet.dataType}</td>
                        <td>{dataSet.activity}</td>
                        <td>{dataSet.dataSource}</td>
                        <td>{dataSet.season}</td>
                        <td>{dataSet.quality}</td>
                        <td>{dataSet.audioAvailable ?? ''}</td>
                        <td>{dataSet.store}</td>
                        <td>
                            {dataSet.validated && (
                                <div>{dataSet.nbQuestionsValidated} rows</div>
                            )}
                        </td>
                    </tr>
                ))}
                <tr>
                    <td colSpan={4}>
                        <div>
                            <strong>{dataSets.filter(dataSet => dataSet.dataType === 'JSON' && dataSet.validated).reduce((total, dataSet) => total + dataSet.totalRows, 0)} good questions</strong>
                        </div>
                        <div>
                            {dataSets.filter(dataSet => dataSet.dataType !== 'JSON' && dataSet.validated).reduce((total, dataSet) => total + dataSet.nbQuestionsValidated, 0)} medium questions
                        </div>
                    </td>
                    <td>
                        <Button onClick={fetchDataSets} variant="link" size="sm">Refresh</Button>
                    </td>
                </tr>
            </tbody>
        </Table>
    )
}

export default DataSetsTable;