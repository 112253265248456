import { useEffect, useState } from 'react';
import { Modal, Table } from 'react-bootstrap';
import { getDataFromAPI } from '../../../utils/query';

import './styles.scss';

const IdeasTicketsByActivityModal = ({ activity, hideModal }) => {
    const [keywords, setKeywords] = useState([]);
    const [info, setInfo] = useState(null);

    useEffect(() => {
        const fetchKeywords = async () => {
            const res = await getDataFromAPI('resources/keywords/?page_size=10000&storeID=' + activity['storeID']);
            setKeywords(res.data['results']);
        }

        const fetchInfo = async () => {
            const res = await getDataFromAPI('resources/get-ideas-tickets-by-activity/?activity=' + activity['activity'] + '&storeID=' + activity['storeID']);
            setInfo(res.data);
        }

        fetchKeywords();
        fetchInfo();
    }, []);

    return (
        <Modal size="lg"
            show={true}
            onHide={hideModal}
            className="ideas-tickets-by-activity-modal"
        >
            <Modal.Header closeButton>
                <Modal.Title>Activity {activity['activity']}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <h2>Number Keywords: {keywords.length}</h2>

                {info && (
                    <>
                        <h2>Ideas</h2>
                        <Table striped bordered hover>
                            <thead>
                                <tr>
                                    <th>Total</th>
                                    <th>Upload Done</th>
                                    <th>Ready To Upload</th>
                                    <th>Pending</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>{info.nbTotalIdeas}</td>
                                    <td>{info.nbUploadDoneIdeas}</td>
                                    <td>{info.nbReadyToUploadIdeas}</td>
                                    <td>{info.nbPendingIdeas}</td>
                                </tr>
                            </tbody>
                        </Table>
                    </>
                )}

                {info && (
                    <>
                        <h2>Tickets</h2>
                        <Table striped bordered hover>
                            <thead>
                                <tr>
                                    <th>Done</th>
                                    <th>Pending</th>
                                    <th>Pending Errors</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>{info.nbTicketsDone}</td>
                                    <td>{info.nbPendingTickets}</td>
                                    <td>{info.nbPendingErrorsTickets}</td>
                                </tr>
                            </tbody>
                        </Table>
                    </>
                )}
            </Modal.Body>
        </Modal>
    );
}
export default IdeasTicketsByActivityModal;