import { Badge, Button, ButtonGroup, Dropdown, Form, Spinner, Table } from 'react-bootstrap';

import './styles.scss';
import { useEffect, useState } from 'react';
import { getDataFromAPI, updateDataAPI } from '../../../../utils/query';
import Constant from '../../../../Constant';
import GenDataTicketModal from '../../gen-data-ticket-modal';
import GenDataTicketIdeaLayout from './gen-data-ticket-idea-layout';
import { useSelector } from 'react-redux';

const GenDataTicketsTable = ({ keyword }) => {
    const seasons = useSelector(state => state.filters.seasons);
    const activities = useSelector(state => state.filters.activities);

    const [tickets, setTickets] = useState([]);
    const [loading, setLoading] = useState(false);
    const [userRoles, setUserRoles] = useState([]);
    const [ideaTypeFilter, setIdeaTypeFilter] = useState('all');
    const [seasonFilter, setSeasonFilter] = useState('all');
    const [qualityFilter, setQualityFilter] = useState('all');
    const [statusFilter, setStatusFilter] = useState('all');
    const [ticketInModal, setTicketInModal] = useState(null);
    const [showTicketModal, setShowTicketModal] = useState(false);

    const [selectedTickets, setSelectedTickets] = useState([]);

    useEffect(() => {
        const fetchTickets = async() => {
            setLoading(true);
            const res = await getDataFromAPI('product-dev/gen-data-tickets/?keywordID=' + keyword.id + '&additionalFields=ideaData');
            setTickets(res.data);
            setLoading(false);
        }

        fetchTickets();
    }, []);

    useEffect(() => {
        setUserRoles(JSON.parse(localStorage.getItem(Constant.LOCAL_STORAGE_ROLES)));
    }, []);

    const save = async(ticket) => {
        setLoading(true);
        await updateDataAPI('PATCH', 'product-dev/gen-data-tickets/' + ticket.id + '/?additionalFields=ideaData', ticket);
        console.log(ticket);
        setLoading(false);
    }

    const deleteTicket = async(ticketID) => {
        if (window.confirm('Are you sure?')) {
            setLoading(true);
            await updateDataAPI('DELETE', 'product-dev/gen-data-tickets/' + ticketID + '/');
            setTickets(prev => prev.filter(t => t.id !== ticketID));
            setLoading(false);
        }
    }

    const clearTicketError = async(type, ticket) => {
        setLoading(true);
        if (type === 'single') {
            const res = await updateDataAPI('PATCH', 'product-dev/gen-data-tickets/' + ticket.id + '/?additionalFields=ideaData', {
                genError: null
            });
            setTickets(prev => prev.map(t => {
                if (t.id === ticket.id) {
                    return res.data;
                }
                return t;
            }));
        } else if (window.confirm('Are you sure?')) {
            await updateDataAPI('POST', 'product-dev/clear-all-seasonal-tickets/', {
                season: ticket.season,
                ideaType: ticket.ideaType,
            })
            setTickets(prev => prev.map(t => {
                if (t.season === ticket.season && t.ideaType === ticket.ideaType) {
                    return {...t, genError: null};
                }
                return t;
            }));
        }
        setLoading(false);
    }

    const deleteSeasonalIdeas = async(ticket) => {
        if (window.confirm('Are you sure?')) {
            setLoading(true);
            await updateDataAPI('POST', 'product-dev/delete-seasonal-ideas/', {
                season: ticket.season,
                ideaType: ticket.ideaType,
                storeIDs: keyword.boomStores,
            });
            setLoading(false);
        }
    }

    const genDataTicket = async(toGenDataTickets) => {
        let confirmRun = false;
        if (toGenDataTickets.filter(ticket => ticket.done).length > 0) {
            confirmRun = window.confirm('This product will be removed and replaced by a new product');
        } else {
            confirmRun = true;
        }

        if (confirmRun) {
            let runError = false;
            const ideas = {};
            setTickets(prev => prev.map(t => {
                if (toGenDataTickets.filter(ticket => ticket.id === t.id).length > 0) {
                    return {...t, locked: true};
                }
                return t;
            }))
            for (let i = 0; i < toGenDataTickets.length; i++) {
                const ticket = toGenDataTickets[i];
                if (ticket.done && ticket.idea) {
                    await updateDataAPI('PATCH', 'product-dev/gen-data-tickets/' + ticket.id + '/?additionalFields=ideaData', {
                        genError: null,
                        idea: null,
                        done: false,
                    });
                    await updateDataAPI('DELETE', 'product-ideas/' + ticket.idea + '/');
                }
                const res = await updateDataAPI('POST', 'product-dev/gen-data-ticket/', {
                    ticketID: ticket.id
                });
                if (!res.data['success']) {
                    runError = true;
                    setTickets(tickets.map(t => {
                        if (t.id === ticket.id) {
                            return {...t, genError: res.data['genError']};
                        }
                        return t;
                    }))
                } else {
                    ideas[ticket.id] = res.data['idea'];
                }
            }

            if (runError) {
                window.alert('error');
            } else {
                window.alert('success');
                setTickets(prev => prev.map(t => {
                    if (toGenDataTickets.filter(ticket => t.id === ticket.id).length > 0) {
                        return {...t, done: true, ideaData: ideas[t.id], locked: false};
                    }
                    return t;
                }));
            }
        }
    }

    const createBoomCard = async(quality) => {
        let store;
        if (keyword.boomStores.length > 1) {
            store = window.prompt('Store', '');
        } else {
            store = keyword.boomStores[0];
        }
        setLoading(true);
        const res = await updateDataAPI('POST', 'product-dev/gen-data-tickets/?additionalFields=dataSetsData,ideaData', {
            ideaType: 'boom_card',
            season: 'common',
            quality,
            store,
            keyword: keyword.id,
        });
        setTickets(prev => prev.concat(res.data));
        setLoading(false);
    }

    return (
        <div className="gen-data-tickets-table mt-3">
            <div className="header">
                <div className="filters">
                    <Form.Group>
                        <Form.Label>Type</Form.Label>
                        <Form.Select value={ideaTypeFilter} onChange={e => setIdeaTypeFilter(e.target.value)}>
                            <option value="all">All</option>
                            {activities.map((activity, index) => (
                                <option value={activity['slug']} key={index}>{activity['label']}</option>
                            ))}
                        </Form.Select>
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Season</Form.Label>
                        <Form.Select value={seasonFilter} onChange={e => setSeasonFilter(e.target.value)}>
                            <option value="all">All</option>
                            {seasons.map(season => (
                                <option key={season.id} value={season.name.toLowerCase().replace(/ /g, "_")}>{season.name}</option>
                            ))}
                        </Form.Select>
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Quality</Form.Label>
                        <Form.Select value={qualityFilter} onChange={e => setQualityFilter(e.target.value)}>
                            <option value="all">all</option>
                            <option value="good">good</option>
                            <option value="medium">medium</option>
                        </Form.Select>
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Status</Form.Label>
                        <Form.Select value={statusFilter} onChange={e => setStatusFilter(e.target.value)}>
                            <option value="all">all</option>
                            <option value="done">done</option>
                            <option value="pending">pending</option>
                        </Form.Select>
                    </Form.Group>
                </div>

                <div className="btns">
                    {selectedTickets.length > 0 && (
                        <Button size="sm" className="mr-r" onClick={() => genDataTicket(selectedTickets)}>
                            Run Selected Tickets
                        </Button>
                    )}
                    <Dropdown as={ButtonGroup}>
                        <Button onClick={() => setShowTicketModal(true)}>Create Ticket</Button>

                        <Dropdown.Toggle split id="create-ticket-dropdown" />

                        <Dropdown.Menu>
                            <Dropdown.Item onClick={() => createBoomCard('medium')}>Create Boom Card Medium</Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                </div>
            </div>

            <Table striped bordered hover>
                <thead>
                    <tr>
                        <th>ID</th>
                        <th>Store</th>
                        <th>Type</th>
                        <th>Season</th>
                        <th>Quality</th>
                        <th>Outline</th>
                        <th>Status</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {tickets.filter(ticket => {
                        if (ideaTypeFilter !== 'all' && ticket.ideaType !== ideaTypeFilter) {
                            return false;
                        }

                        if (seasonFilter !== 'all' && ticket.season !== seasonFilter) {
                            return false;
                        }

                        if (qualityFilter !== 'all' && ticket.quality !== qualityFilter) {
                            return false;
                        }

                        if (statusFilter !== 'all' && ((ticket.done && statusFilter === 'pending') || (!ticket.done && statusFilter === 'done'))) {
                            return false;
                        }

                        return true;
                    }).map((ticket, index) => (
                        <tr key={ticket.id}>
                            <td>
                                <div>{index + 1}</div>
                                <div>
                                    <Form.Check
                                        type="checkbox"
                                        onChange={() => {
                                            if (selectedTickets.filter(t => t.id === ticket.id).length === 0) {
                                                setSelectedTickets(selectedTickets.concat(ticket));
                                            } else {
                                                setSelectedTickets(selectedTickets.filter(t => t.id !== ticket.id));
                                            }
                                        }}
                                        checked={selectedTickets.filter(t => t.id === ticket.id).length > 0} />
                                </div>
                                <div className="ticket-fingerprint">
                                    <div>Ticket ID: <a href={Constant.STUDIO_API + 'admin/product_dev/gendataticket/' + ticket.id + '/'} target="_blank">{ticket.id}</a></div>
                                    {ticket.genError && (
                                        <div>
                                            <div>Error: {ticket.genError}</div>
                                            {userRoles.indexOf('admin') > -1 && (
                                                <div className="clear-ticket-errors">
                                                    <span onClick={() => clearTicketError('single', ticket)}>clear</span>
                                                    {ticket.season !== 'common' && (
                                                        <span onClick={() => clearTicketError('all', ticket)}>clear all {ticket.season} tickets</span>
                                                    )}
                                                </div>
                                            )}
                                        </div>
                                    )}
                                    {userRoles.indexOf('admin') > -1 && ticket.done && ticket.season !== 'common' && userRoles.indexOf('admin') > -1 && (
                                        <div className="clear-ticket-errors">
                                            <span onClick={() => deleteSeasonalIdeas(ticket)}>delete all {ticket.season} ideas</span>
                                        </div>
                                    )}
                                </div>
                            </td>
                            <td>{ticket.store}</td>
                            <td>{ticket.ideaType}</td>
                            <td>{ticket.season}</td>
                            <td>{ticket.quality}</td>
                            <td className="outline-cell">
                                <GenDataTicketIdeaLayout
                                    disabled={ticket.done}
                                    layout={ticket.ideaLayout}
                                    dataSets={keyword.dataSets}
                                    updateLayout={layout => {
                                        setTickets(tickets.map(t => {
                                            if (t.id === ticket.id) {
                                                return {...t, ideaLayout: layout};
                                            }

                                            return t;
                                        }));
                                    }} />
                            </td>
                            <td>
                                {ticket.done ? (
                                    <Badge bg="success">DONE</Badge>
                                ) : (
                                    <Badge bg="secondary">pending</Badge>
                                )}
                            </td>
                            <td className="btns-cell">
                                {ticket.done ? (
                                    <div className="idea">
                                        <img src={ticket.ideaData.thumbnail} />
                                        <div>
                                            <a href={'/content-studio/?customID=' + ticket.ideaData.customID} target="_blank">
                                                Store {ticket.ideaData.storeID} - {ticket.ideaData.customID}. {ticket.ideaData.title}
                                            </a>
                                        </div>
                                        <div>
                                            {ticket.ideaData.status && (
                                                <Badge bg="secondary">{ticket.ideaData.status}</Badge>
                                            )}
                                        </div>
                                    </div>
                                ) : (
                                    <div>
                                        <Button size="sm" onClick={() => save(ticket)} variant="success">Save</Button>
                                        <Button size="sm" onClick={() => {
                                            setShowTicketModal(true);
                                            setTicketInModal(ticket);
                                        }}>Edit</Button>
                                        <Button size="sm" variant="danger" onClick={() => deleteTicket(ticket.id)}>Delete</Button>
                                    </div>
                                )}

                                {ticket.locked ? (
                                    <span>running...</span>
                                ) : (
                                    <Button size="sm" onClick={() => genDataTicket([ticket])}>Run</Button>
                                )}
                            </td>
                        </tr>
                    ))}
                </tbody>
            </Table>

            {showTicketModal && (
                <GenDataTicketModal
                    ticket={ticketInModal}
                    setTickets={setTickets}
                    setLoading={setLoading}
                    keyword={keyword}
                    hideModal={() => {
                        setShowTicketModal(false);
                        setTicketInModal(null);
                    }}
                />
            )}

            {loading && (
                <div className="loading-container">
                    <Spinner animation="border" variant="light" />
                </div>
            )}
        </div>
    );
}

export default GenDataTicketsTable;