import { Accordion, Button, ButtonGroup, Dropdown, Form, Modal, Spinner, Table } from 'react-bootstrap';

import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setSelectedStoreID, setStore } from '../../../filters/filtersSlice';
import FilterByStore from '../../../filters/store';
import IdeaModal from '../../../pages/idea-list/idea-modal';
import { getDataFromAPI, updateDataAPI } from '../../../utils/query';
import BundleItemsModal from '../../product-map/ideas-cell/bundle-items-modal';
import KeywordInfoModal from '../../product-map/keyword-info-modal';
import ImportNewKeywordsByJSONModal from '../import-new-keywords-by-json-modal';
import KeywordBundleModal from '../keyword-bundle-modal';
import KeywordList from '../keyword-list';
import './styles.scss';

const StoreKeywords = ({}) => {
    const dispatch = useDispatch();
    const {selectedStoreID, stores} = useSelector(state => state.filters);

    const [keywords, setKeywords] = useState(null);

    const [filteredMarketplace, setFilteredMarketplace] = useState('boom');

    const [storeInModal, setStoreInModal] = useState(null);
    const [loading, setLoading] = useState(false);
    const [showKeywordBundleModal, setShowKeywordBundleModal] = useState(false);
    const [bundleInModal, setBundleInModal] = useState(null);
    const [bundleIDInBundleItemsModal, setBundleIDInBundleItemsModal] = useState(null);
    const [ideaInModal, setIdeaInModal] = useState(null);
    const [showNewKeywordModal, setShowNewKeywordModal] = useState(false);
    const [showImportNewKeywordsByJSONModal, setShowImportNewKeywordsByJSONModal] = useState(false)

    // const removeKeywordFromStore = async(keyword) => {
    //     if (window.confirm('Are you sure?')) {
    //         setLoading(true);
    //         await updateDataAPI('POST', 'resources/remove-keyword-from-store/', {
    //             keywordID: keyword.id,
    //             storeID: keyword.storeID,
    //             marketplace: 'boom'
    //         });

    //         const res = await getDataFromAPI('stores/' + keyword.storeID + '?additionalFields=keywords');
    //         setStores(prev => prev.map(store => {
    //             if (store.id === res.data['id']) {
    //                 return res.data;
    //             }
    //             return store;
    //         }));

    //         setLoading(false);
    //     }
    // }

    // const duplicateKeywordToOtherStore = async(keyword) => {
    //     const targetStoreID = window.prompt('Duplicate keyword to Store', '');
    //     if (targetStoreID) {
    //         setLoading(true);
    //         await updateDataAPI('POST', 'resources/duplicate-keyword-to-other-store/', {
    //             keywordID: keyword.id,
    //             storeID: targetStoreID,
    //             marketplace: 'boom'
    //         });

    //         const res = await getDataFromAPI('stores/' + targetStoreID + '?additionalFields=keywords');
    //         setStores(prev => prev.map(store => {
    //             if (store.id === res.data['id']) {
    //                 return res.data;
    //             }
    //             return store;
    //         }));

    //         setLoading(false);
    //     }
    // }

    useEffect(() => {
        const id = (new URLSearchParams(window.location.search)).get('id');
        dispatch(setSelectedStoreID(id));
    }, []);

    useEffect(() => {
        if (selectedStoreID && selectedStoreID !== 'all') {
            fetchStoreKeywords();
        }
    }, [selectedStoreID]);

    const fetchStoreKeywords = async() => {
        setLoading(true);
        const res = await getDataFromAPI('resources/get-keywords-by-store/?storeID=' + selectedStoreID);
        setKeywords(res.data);
        setLoading(false);
    }

    const saveStoreInModal = async() => {
        setLoading(true);
        const res = await updateDataAPI('PATCH', 'stores/' + storeInModal.id + '/', {
            subjects: storeInModal.subjects,
            grades: storeInModal.grades,
        });
        dispatch(setStore(res.data));
        setStoreInModal(null);
        setLoading(false);
    }

    const deleteBundle = async(storeID, bundle) => {
        setLoading(true);
        await updateDataAPI('DELETE', 'resources/keyword-bundles/' + bundle.id + '/');
        await fetchStoreKeywords();
    }

    const showIdeaModal = async(ideaID) => {
        setLoading(true);
        const res = await getDataFromAPI('product-ideas/' + ideaID + '/');
        setIdeaInModal(res.data);
        setLoading(false);
    }

    return (
        <div className="store-keywords">
            <div className="header">
                <div className="filters">
                    <Form.Select value={filteredMarketplace} onChange={e => setFilteredMarketplace(e.target.value)}>
                        <option value="all">all</option>
                        <option value="tpt">tpt</option>
                        <option value="boom">boom</option>
                    </Form.Select>
                    <FilterByStore marketplace="boom" />
                </div>
                <div className="btns">
                    {selectedStoreID !== 'all' && (
                        <>
                            <Button onClick={() => setShowKeywordBundleModal(true)}>Add New Bundle</Button>
                            <Dropdown as={ButtonGroup} size="sm">
                                <Button onClick={() => setShowNewKeywordModal(true)}>Add New Keyword</Button>

                                <Dropdown.Toggle split id="add-new-keyword-dropdown" variant="info" />

                                <Dropdown.Menu>
                                    <Dropdown.Item onClick={() => setShowImportNewKeywordsByJSONModal(true)}>Import JSON</Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                        </>
                    )}
                </div>
            </div>

            {keywords !== null && (
                <Table striped bordered hover>
                    <thead>
                        <tr>
                            <th>ID</th>
                            <th>Bundles</th>
                            <th>Keywords</th>
                        </tr>
                    </thead>
                    <tbody>
                        {stores.filter(store => {
                            if (filteredMarketplace !== 'all' && store.marketplace !== filteredMarketplace) {
                                return false;
                            }

                            if (selectedStoreID !== 'all' && store.id != selectedStoreID) {
                                return false;
                            }

                            return true;
                        }).map(store => (
                            <tr key={store.id}>
                                <td className="store-cell">
                                    <div>
                                        <a href={'/ideas?selectedStoreID=' + store.id} target="_blank">
                                            {store.id}
                                        </a>
                                        <span onClick={() => fetchStoreKeywords()} className="refresh-store-btn">refresh</span>
                                    </div>
                                    {(store.boomSubjects !== null && store.boomClass !== null) ? (
                                        <div>Boom Auto: {store.boomSubjects} - {store.boomClass}</div>
                                    ) : (
                                        <>
                                            <div className="edit-store-info" onClick={() => setStoreInModal(store)}>Subjects: {store.subjects}</div>
                                            <div className="edit-store-info" onClick={() => setStoreInModal(store)}>Grades: {store.grades}</div>
                                        </>
                                    )}
                                    <div>Total: {keywords.nbKeywords} keywords</div>
                                    <div>Uploaded: {keywords.nbKeywordsHasProduct} keywords</div>
                                </td>
                                <td className="bundles-cell">
                                    {keywords && (
                                        <div className="bundles">
                                            <Accordion defaultActiveKey="0">
                                                {keywords.bundles.map((bundle, bundleIndex) => (
                                                    <Accordion.Item eventKey={bundleIndex} key={bundleIndex}>
                                                        <Accordion.Header>{bundleIndex + 1}. {bundle.name} - {bundle.validated ? 'VALIDATED' : ''} - {bundle.ideas.length > 0 ? bundle.ideas.length + ' bundles' : ''}</Accordion.Header>
                                                        <Accordion.Body>
                                                            <h3>Keywords</h3>
                                                            <KeywordList
                                                                keywords={bundle.keywords}
                                                                setLoading={setLoading}
                                                                // removeKeywordFromStore={removeKeywordFromStore}
                                                                // duplicateKeywordToOtherStore={duplicateKeywordToOtherStore}
                                                            />

                                                            {bundle.ideas.length > 0 && (
                                                                <div>
                                                                    <h3>Bundles</h3>
                                                                    <Table striped bordered hover>
                                                                        <thead>
                                                                            <tr>
                                                                                <th>ID</th>
                                                                                <th>Thumbnail</th>
                                                                                <th>Title</th>
                                                                                <th></th>
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                            {bundle.ideas.map(idea => (
                                                                                <tr key={idea.customID}>
                                                                                    <td>{idea.customID}</td>
                                                                                    <td>
                                                                                        <img src={idea.thumbnail} />
                                                                                    </td>
                                                                                    <td>
                                                                                        <div className="bundle-title" onClick={() => showIdeaModal(idea.id)}>
                                                                                            {idea.title}
                                                                                        </div>
                                                                                    </td>
                                                                                    <td>
                                                                                        <div className="mb-3">
                                                                                            {idea.currentStep}
                                                                                        </div>
                                                                                        <Button size="sm" variant="info" onClick={() => setBundleIDInBundleItemsModal(idea.id)}>
                                                                                            Show Items
                                                                                        </Button>
                                                                                    </td>
                                                                                </tr>
                                                                            ))}
                                                                        </tbody>
                                                                    </Table>
                                                                </div>
                                                            )}
                                                            <div className="btns">
                                                                <div>
                                                                    <Button size="sm" variant="secondary" onClick={() => {
                                                                        setBundleInModal(bundle);
                                                                        setShowKeywordBundleModal(true);
                                                                    }}>Edit</Button>
                                                                    <Button size="sm" variant="danger" onClick={() => deleteBundle(store.id, bundle)}>Delete</Button>
                                                                </div>
                                                            </div>
                                                        </Accordion.Body>
                                                    </Accordion.Item>
                                                ))}
                                            </Accordion>
                                        </div>
                                    )}
                                </td>
                                <td className="keywords-cell">
                                    {keywords && (
                                        <KeywordList
                                            keywords={keywords.keywords}
                                            setLoading={setLoading}
                                            // removeKeywordFromStore={removeKeywordFromStore}
                                            // duplicateKeywordToOtherStore={duplicateKeywordToOtherStore}
                                            refreshStore={fetchStoreKeywords}
                                        />
                                    )}
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
            )}

            {storeInModal !== null && (
                <Modal show={true} onHide={() => setStoreInModal(null)}>
                    <Modal.Header closeButton>
                        Store {storeInModal.id}
                    </Modal.Header>
                    <Modal.Body>
                        <Form.Group className="mb-3">
                            <Form.Label>Subjects</Form.Label>
                            <Form.Control value={storeInModal.subjects} onChange={e => setStoreInModal({...storeInModal, subjects: e.target.value})} />
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Grades</Form.Label>
                            <Form.Control value={storeInModal.grades} onChange={e => setStoreInModal({...storeInModal, grades: e.target.value})} />
                        </Form.Group>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button onClick={saveStoreInModal}>Save</Button>
                    </Modal.Footer>
                </Modal>
            )}

            {showKeywordBundleModal && selectedStoreID !== 'all' && (
                <KeywordBundleModal
                    bundle={bundleInModal}
                    loading={loading}
                    setLoading={setLoading}
                    refreshStore={fetchStoreKeywords}
                    hideModal={() => {
                        setShowKeywordBundleModal(false);
                        setBundleInModal(null);
                    }}
                    storeID={selectedStoreID}
                />
            )}

            {bundleIDInBundleItemsModal !== null && (
                <BundleItemsModal
                    bundleID={bundleIDInBundleItemsModal}
                    hideModal={() => setBundleIDInBundleItemsModal(null)}
                />
            )}

            {ideaInModal !== null && (
                <IdeaModal
                    idea={ideaInModal}
                    defaultStore={ideaInModal.store}
                    closeModal={() => {
                        setIdeaInModal(null);
                    }}
                />
            )}

            {showNewKeywordModal && (
                <KeywordInfoModal
                    setLoading={setLoading}
                    hideModal={() => setShowNewKeywordModal(false)}
                    removeKeyword={fetchStoreKeywords}
                />
            )}

            {showImportNewKeywordsByJSONModal && (
                <ImportNewKeywordsByJSONModal
                    hideModal={() => setShowImportNewKeywordsByJSONModal(false)}
                    refreshKeywords={fetchStoreKeywords}
                />
            )}

            {loading && (
                <div className="loading-container">
                    <Spinner animation="border" variant="light" />
                </div>
            )}
        </div>
    )
}

export default StoreKeywords;